<template>
    <div class="uploadimg">
        <!-- 

        style="margin-left:16px;height:20px;"

         -->
        <el-upload
        :action="$store.getters.requestBaseUrl + upbase"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :data="upData">
            <slot/>
        </el-upload>
    </div>
</template>
<script>
export default {
    props:{
        impose:{
            type: Boolean,
            default: true
        },
        upbase:{
            type: String,
            default: 'Home/update_img'
        }
    },
    name:'uploadimg',
    data(){
        return{
            upData:{
                token: this.$store.getters.token
            }
        }
    },
    methods:{
        handleAvatarSuccess(res, file) {
            if(this.impose){
                this.$emit('getcallback', res.data)
                return
            }
            this.$emit('getimg',res.data.url)
        },
        beforeAvatarUpload(file) {
            if(this.impose){
                if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    this.$message.error('请上传excel文件！')
                    return false
                }
                return true
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
    }
}
</script>