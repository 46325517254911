import * as api from './http'

/* 修改个人信息(机构) */
export function org_edit_user(params){
    return api.postApi('Organ/edit_user', params)
}

/* 组织架构—组织列表 */
export function frame_list(params){
    return api.postApi('Organ/frame_list', params)
}

/* 组织架构—组织列表 */
export function add_next(params){
    return api.postApi('Organ/add_next', params)
}

/* 组织架构—组织列表 */
export function new_next(params){
    return api.postApi('Organ/new_next', params)
}

/* 组织架构—组织列表 */
export function organ_member_list(params){
    return api.postApi('Organ/organ_member_list', params)
}

/* 组织架构—组织列表 */
export function add_member(params){
    return api.postApi('Organ/add_member', params)
}

/* 组织架构—组织列表 */
export function member_delall(params){
    return api.postApi('Organ/member_delall', params)
}

/* 组织架构—调整部门 */
export function new_branch(params){
    return api.postApi('Organ/new_branch', params)
}