<template>
    <div class="addMember">
        <table>
            <tr>
                <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属用户组</td>
                <td>
                    <el-select @change="se1change" v-model="addMemberObj[0]" style="width:300px;" placeholder="请选择">
						<el-option>请选择</el-option>
                        <el-option
                        v-for="item in select1"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属部门</td>
                <td>
                    <el-select @change="se2change" v-model="addMemberObj[1]" style="width:300px;" placeholder="请选择">
						<el-option>请选择</el-option>
                        <el-option
                        v-for="item in select2"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属科室</td>
                <td>
                    <el-select @change="se3change" v-model="addMemberObj[2]" style="width:300px;" placeholder="请选择">
						<el-option>请选择</el-option>
                        <el-option
                        v-for="item in select3"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属小组</td>
                <td>
                    <el-select @change="se4change" v-model="addMemberObj[3]" style="width:300px;" placeholder="请选择">
						<el-option>请选择</el-option>
                        <el-option
                        v-for="item in select4"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import { frame_list } from '@/utils/Api/orgList'
export default {
    name:'addMember',
    data(){
        return{
            addMemberObj:[],
            select1:[],                      // 四级联动1
            select2:[],                      // 四级联动2
            select3:[],                      // 四级联动3
            select4:[],                      // 四级联动4
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            this.cbkData()
        },
        async cbkData(value){
            this.select1 = await frame_list()
            // if(this.select1.length < 1){
                this.addMemberObj[0] = ''
                this.addMemberObj[1] = ''
                this.addMemberObj[2] = ''
                this.addMemberObj[3] = ''
            // }
			this.$forceUpdate()
        },
        async se1change(value){
            this.select2 = await frame_list({pid: value})
            // if(this.select2.length < 1){
                this.addMemberObj[1] = ''
                this.addMemberObj[2] = ''
                this.addMemberObj[3] = ''
            // }
			this.$forceUpdate()
        },
        async se2change(value){
            this.select3 = await frame_list({pid: value})
            // if(this.select3.length < 1){
                this.addMemberObj[2] = ''
                this.addMemberObj[3] = ''
            // }
			this.$forceUpdate()
        },
        async se3change(value){
            this.select4 = await frame_list({pid: value})
            // if(this.select4.length < 1){
                this.addMemberObj[3] = ''
            // }
			this.$forceUpdate()
        },
        async se4change(value){
            // this.select2 = await frame_list({pid: value})
        }
    },
    watch:{
        addMemberObj(){
            let obj = {
                level_one_id: this.addMemberObj[0] ? this.addMemberObj[0] : '',
                level_two_id: this.addMemberObj[1] ? this.addMemberObj[1] : '',
                level_three_id: this.addMemberObj[2] ? this.addMemberObj[2] : '',
                level_four_id: this.addMemberObj[3] ? this.addMemberObj[3]: '',
            }
            this.$emit('memberData', obj)
        }
    }
}
</script>
<style lang="scss" scoped>
.addMember{

}
</style>